import React from "react";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Button,
} from "@mui/material";
import ImageGallery from "react-image-gallery";

import WbIncandescentOutlinedIcon from "@mui/icons-material/WbIncandescentOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import LocalGasStationOutlinedIcon from "@mui/icons-material/LocalGasStationOutlined";
import PermDataSettingIcon from "@mui/icons-material/PermDataSetting";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CallIcon from "@mui/icons-material/Call";

const CarDetail = () => {
  let { id } = useParams();
  const [car, setCar] = useState([]);
  const [price, setPrice] = useState('');
  const [date, setDate] = useState('');
  const [images, setImages] = React.useState([]);
  
  useEffect(() => {
    const setImgs = (url) => {
      const img_item = {
        original: url,
        thumbnail: url,
      };
      setImages((images) => [...images, img_item]);
    };

    async function getCar() {
      const requestOptions = {
        method: "GET",
        headers: {
          accept: "application/json",
        },
      };
      const car_id = id.toString();
      const response = await fetch("https://sincrocars-backend.herokuapp.com/public/car/" + car_id, requestOptions);
      if (!response.ok) {
        console.log("A gépjármű lekérése nem sikerült");
      } else {
        const response_json = await response.json();
        setCar(response_json);
        const formatedPrice = response_json.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        setPrice(formatedPrice);
        const formatedDate = response_json.firstreg.substring(0, 7);
        setDate(formatedDate);
        console.log(response_json);
        response_json.carimgs.map((img) => setImgs(img.fileUrl));
      }
    }
    getCar();
  }, [id]);
  return (
    <div>
      <Grid
        container
        rowSpacing={5}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        style={{ marginTop: "20px" }}
      >
        <Grid item md={8} sm={12}>
          <ImageGallery items={images} showBullets={true} showThumbnails={false}/>
        </Grid>
        <Grid item md={4} sm={12}>
          <Typography variant="h3" align="left" gutterBottom>
            {car.brand}
          </Typography>
          <Typography variant="h4" align="left" gutterBottom color="secondary">
            {price} Ft
          </Typography>
          <Divider textAlign="left">Tulajdonságok</Divider>
          <List>
            <ListItem disablePadding>
              <ListItemIcon>
                <EventNoteOutlinedIcon color="info"/>
              </ListItemIcon>
              <ListItemText primary={"Évjárat: " + date} />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon>
                <ArchitectureIcon color="info"/>
              </ListItemIcon>
              <ListItemText
                primary={"Motor hengerűrtartalma: " + car.cubCapacity}
              />
            </ListItem>

            <ListItem disablePadding>
              <ListItemIcon>
                <CarRepairIcon color="info"/>
              </ListItemIcon>
              <ListItemText primary={"Hajtás: " + car.driveType} />
            </ListItem>

            <ListItem disablePadding>
              <ListItemIcon>
                <LocalGasStationOutlinedIcon color="info"/>
              </ListItemIcon>
              <ListItemText primary={"Üzemanyag: " + car.fuel} />
            </ListItem>

            <ListItem disablePadding>
              <ListItemIcon>
                <PermDataSettingIcon color="info"/>
              </ListItemIcon>
              <ListItemText
                primary={"Váltó fajtája: " + car.gearBoxType + " váltó"}
              />
            </ListItem>

            <ListItem disablePadding>
              <ListItemIcon>
                <CallMadeIcon color="info"/>
              </ListItemIcon>
              <ListItemText primary={"Teljesítmény: " + car.powerKw + " kw"} />
            </ListItem>

            <ListItem disablePadding>
              <ListItemIcon>
                <WbIncandescentOutlinedIcon color="info"/>
              </ListItemIcon>
              <ListItemText primary={"Megjegyzés: " + car.desc} />
            </ListItem>
          </List>

          <Button
            size="large"
            variant="contained"
            href="tel:+36309432245"
            startIcon={<CallIcon />}
            style={{ float: "left", arginTop: "30px", marginTop: "15px" }}
          >
            +36 30 943 2245
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default CarDetail;
