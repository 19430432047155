import React from "react";
import { Container } from "@mui/material";
import NewCar from "./dashboard-components/NewCar";
import { Button, Typography, Divider } from "@mui/material";
import { useState, useContext } from "react";
import CarList from "./dashboard-components/CarList";
import { Link } from "react-router-dom";

import { UserContext } from "../../context/UserContext";

const Dashboard = () => {
  const [show, setShow] = useState(false);
  const [token,] = useContext(UserContext);

  const controlNewCarPanel = () => {
    setShow(!show);
  };

  return (
    <div>
      {token == null ? (
        <>
          <Typography
            variant="h2"
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            Ehhez előbb be kell jelentkezni
          </Typography>
          <Link
            to="/login"
            style={{
              textDecoration: "none",
            }}
          >
            <Button variant="contained">Bejelentkezés!</Button>
          </Link>
        </>
      ) : (
        <>
          <Container style={{ paddingTop: "20px" }}>
            {show ? (
              <div>
                <NewCar controlNewCarPanel={controlNewCarPanel} />
              </div>
            ) : (
              <Button
                onClick={() => controlNewCarPanel()}
                variant="contained"
                color="secondary"
                size="large"
              >
                Új Hírdetés feladása
              </Button>
            )}
            <Divider>
              <Typography
                variant="h2"
                style={{ marginTop: "20px", marginBottom: "20px" }}
              >
                Hírdetéseim
              </Typography>
            </Divider>

            <CarList />
          </Container>
        </>
      )}
    </div>
  );
};

export default Dashboard;
