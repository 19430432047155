import { Grid, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import DirectionsCarOutlinedIcon from "@mui/icons-material/DirectionsCarOutlined";
import { Link } from "react-router-dom";
import PublicCarCard from "./public-car-components/PublicCarCard";
import { useEffect, useState } from "react";

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const textAlignfunc = () => {
    if (isMobile) {
      return "center";
    } else {
      return "left";
    }
  };
  const textAlign = textAlignfunc();

  const [carlist, setCarlist] = useState([]);
  useEffect(() => {
    const getCars = async () => {
      const requestOptions = {
        method: "GET",
        headers: {
          accept: "application/json",
        },
      };

      const response = await fetch(
        "https://sincrocars-backend.herokuapp.com/public/car/highlighted/",
        requestOptions
      );
      if (!response.ok) {
        console.log("Houston baj van");
      } else {
        const data = await response.json();
        setCarlist(data);
      }
    };
    getCars();
  }, []);
  return (
    <div>
      <Grid
        bgcolor="primary.main"
        container
        style={{
          marginTop: "30px",
          marginBottom: "15px",
          padding: "45px",
          borderRadius: "20px",
        }}
        color="primary.contrastText"
      >
        <Grid item md={8} sm={12}>
          <Typography variant="subtitle1" align={textAlign} gutterBottom>
            GÉPJÁRMŰ KERESKEDELEM, ÜGYINTÉZÉS
          </Typography>
          <Typography variant="h2" align={textAlign} gutterBottom>
            Találd meg a tökéletes autód
          </Typography>
          <Typography
            align={textAlign}
            maxWidth={600}
            lineHeight={2}
            style={{ marginBottom: "20px" }}
          >
            A SincroCars használt autó kínálatában újszerű és szép állapotban
            lévő gépjárművekkel találkozhat. Cégünk több mint húsz éves
            tapasztalattal rendelkezik járművek adás vételében és teljes körű
            ügyintézésében
          </Typography>
          <Link to="/cars" style={{ textDecoration: "none", float: "left" }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              endIcon={<DirectionsCarOutlinedIcon />}
            >
              TEKINTSE MEG A TELJES KÍNÁLATUNK
            </Button>
          </Link>
        </Grid>
      </Grid>

      <Grid
        container
        style={{ marginTop: "50px", marginBottom: "20px", padding: "5px" }}
      >
        <Grid item md={12} sm={12}>
          <Typography variant="h2" gutterBottom>
            Több mint 20 éves tapasztalat
          </Typography>
        </Grid>
      </Grid>

      <Grid spacing={6} container style={{ marginBottom: "20px" }}>
        <Grid item md={4} sm={12}>
          <Typography variant="h5" gutterBottom>
            Használt autó kereskedelem
          </Typography>
          <Typography>
            Nálunk fontos, hogy az ügyfél vásárlás után elégedetten távozzon.
          </Typography>
        </Grid>

        <Grid item md={4} sm={12}>
          <Typography variant="h5" gutterBottom>
            Regisztrációs adó ügyintézés
          </Typography>
          <Typography>
            Külföldről behozott gépjárművek regisztációs adó ügyintézésében
            keressenek minket bizalommal.
          </Typography>
        </Grid>
        <Grid item md={4} sm={12}>
          <Typography variant="h5" gutterBottom>
            Műszaki vizsgáztatás
          </Typography>
          <Typography>
            Gépjárműve forgalomba helyezésének alapvető követelménye, műszaki
            állapotának kifogástalansága.
          </Typography>
        </Grid>
        <Grid item md={4} sm={12}>
          <Typography variant="h5" gutterBottom>
            Eredet vizsgáztatás
          </Typography>
          <Typography>
            Az eredetiség vizsgálattal ellenőrizhető, hogy a járművünk teljesen
            azonos-e a gyári, eredeti állapottal. Ez a költség minden adás-vétel
            alkalmával felmerül.
          </Typography>
        </Grid>
        <Grid item md={4} sm={12}>
          <Typography variant="h5" gutterBottom>
            Okmányiroda ügyintézés
          </Typography>
          <Typography>
            A hosszadalmas ügyintézést, amely a külföldről behozott gépjárműve
            magyarországi forgalomba helyezéséhez szükséges,
            meghatalmazottjaként elvégezzük.
          </Typography>
        </Grid>
        <Grid item md={4} sm={12}>
          <Typography variant="h5" gutterBottom>
            Honosítás
          </Typography>
          <Typography>
            Minden magyaroszágon forgalomba helyezni kívánt, külföldről behozott
            jármű, honosítási eljáráson kell átessen.
          </Typography>
        </Grid>

        <Grid item md={4} sm={12}>
          <Typography variant="h5" gutterBottom>
            Gépjármű beszerzés
          </Typography>
          <Typography>
            Vállaljuk külföldön kinézett gépjárművek Magyarországra hozatalát,
            akár a teljes folyamat támogatásával, a beszerzéstől, a
            magyarországi forgalomba helyezésig.
          </Typography>
        </Grid>

        <Grid item md={4} sm={12}>
          <Typography variant="h5" gutterBottom>
            Autómentés
          </Typography>
          <Typography>
            Az ország teljes területén, Debrecentől számított távolsági
            költségek figyelembe vételével igénybe vehető az autómentési
            szolgáltatásunk.
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        style={{ marginTop: "50px", marginBottom: "20px", padding: "5px" }}
      >
        <Grid item md={12} sm={12}>
          <Typography variant="h2" gutterBottom>
            Kiemelt ajánlatok
          </Typography>
        </Grid>
      </Grid>

      <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {carlist.map((car) => (
          <Grid item xs={12} md={4} key={car.id}>
            <PublicCarCard car={car} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Home;
