import "./App.css";
import Navbar from "./header/Navbar";
import { Container } from "@mui/material";
import { Routes, Route } from "react-router";
import Home from "./pages/Home";
import Vehicles from "./pages/Vehicles";
import Contact from "./pages/Contact";
import Dashboard from "./pages/admin-pages/Dashboard";
import Profile from "./pages/admin-pages/Profile";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Login from "./pages/admin-pages/Login";
import Footer from "./footer/Footer";
import CarDetail from "./pages/public-car-components/CarDetail";

import { CssBaseline } from "@mui/material";
function App() {
  
  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline enableColorScheme />
            <Navbar />
            <Container maxWidth="lg">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/cars" element={<Vehicles />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/login" element={<Login />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/car/:id" element={<CarDetail />} />
              </Routes>
            </Container>
            <Footer />
      </LocalizationProvider>
    </div>
  );
}

export default App;
