import React from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider, createTheme } from "@mui/material/styles";

export const ColorModeContext = React.createContext({
  toggleColorMode: () => {},
});

const ToggleColorMode = (props) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = React.useState(prefersDarkMode ? 'dark' : 'light');
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: mode === "light" ? "#3d4f6f" : "#424242",
            contrastText: "#fff",
          },
          secondary: {
            main: "#ffbc8b",
            contrastText: "#fff",
          },
          info: {
            main: "#959BAF",
            contrastText: "#fff",
          },
          text: {
            primary: mode === "light" ? "#3d4f6f" : "#F8FBFE",
            secondary: mode === "light" ? "#959BAF" : "#F8FBFE",
          }
/*
          background: {
            default: "#11cb5f",
          },
          text: {
            primary: "#ffffff",
          },
*/
          
        },
        typography: {
          h2: {
            fontFamily: ["Alfa Slab One","crusive",].join(","),
            fontSize: 40
          },
          h3: {
            fontFamily: ["Alfa Slab One","crusive",].join(","),
          },
          h6: {
            fontFamily: ["Alfa Slab One","crusive",].join(","),
          },
          
          h5: {
            fontWeight: 'bold',
            color: mode === "light" ? "text.primary" : "#ffbc8b"
          },
          subtitle1:{
            color:"#ffbc8b",
          }

          
        },
      }),
    [mode]
  );
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default ToggleColorMode;
