import React from "react";
import { Typography, TextField, Container, Button } from "@mui/material";
import { useState, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [token, setToken] = useContext(UserContext);
  const navigate = useNavigate();

  const submitLogin = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: JSON.stringify(
        `grant_type=&username=${email}&password=${password}&scope=&client_id=&client_secret=`
      ),
    };

    const response = await fetch("https://sincrocars-backend.herokuapp.com/login", requestOptions);
    const data = await response.json();

    if (!response.ok) {
      setErrorMessage("Hiba: " + data.detail);
      console.log(errorMessage);
    } else {
      setToken(data.access_token);
      navigate("/dashboard");
    }
  };

  const handleSubmit = (e) => {
    submitLogin();
  };
  return (
    <div>
      {token == null ? (
        <>
          <Typography variant="h3" paddingTop="20px">
            Bejelentkezés
          </Typography>
          <Container maxWidth="sm">
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Email Cím"
              type="email"
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="password"
              label="Jelszó"
              type="password"
              fullWidth
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button onClick={handleSubmit} variant="contained">
              Rendben
            </Button>
          </Container>
        </>
      ) : (
        <>
          <Typography variant="h3" paddingTop="20px">
            Már be vagy jelentkezve
          </Typography>
        </>
      )}
    </div>
  );
};

export default Login;
