import { Grid, Typography } from "@mui/material";
import PublicCarCard from "./public-car-components/PublicCarCard";
import { useState, useEffect } from "react";

const Vehicles = () => {
  const [carlist, setCarlist] = useState([]);
  useEffect(() => {
    const getCars = async () => {
      const requestOptions = {
        method: "GET",
        headers: {
          accept: "application/json",
        },
      };

      const response = await fetch("https://sincrocars-backend.herokuapp.com/public/car/published/", requestOptions);
      if (!response.ok) {
        console.log("Houston baj van");
      } else {
        const data = await response.json();
        setCarlist(data);
      }
    };
    getCars();
  }, []);
  return (
    <div>
      <Grid
        container
        style={{ marginTop: "50px", marginBottom: "20px", padding: "5px" }}
      >
        <Grid item xs={12} md={12} >
          <Typography variant="h2" gutterBottom>
            Gépjármű kínálatunk
          </Typography>
        </Grid>
      </Grid>

      <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {carlist.map((car) => (
          <Grid item xs={12} md ={4} key={car.id}>
            <PublicCarCard car={car}/>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Vehicles;
