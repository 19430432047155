import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  TextField,
  FormControlLabel,
  Switch,
  Container,
  Stack,
  useMediaQuery,
  useTheme,
  Autocomplete,
  Grid,
  Card,
  CardMedia,
  CardActions,
  ButtonGroup,
  CircularProgress,
} from "@mui/material";
import { useState, useContext, useEffect } from "react";
import { UserContext } from "../../../context/UserContext";
import { DesktopDatePicker, MobileDatePicker } from "@mui/lab";
import ImageUploading from "react-images-uploading";
import DeleteIcon from "@mui/icons-material/Delete";

const EditCar = (props) => {
  const [isProgress, setIsProgress] = useState(false);
  const theme = useTheme();
  const [token] = useContext(UserContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [brand, setBrand] = useState(props.car.brand);
  const [isPublic, setIsPublic] = useState(props.car.published);
  const handleCheckChange = (event) => {
    setIsPublic(event.target.checked);
  };
  const [price, setPrice] = useState(props.car.price);
  const [firstReg, setFirstReg] = useState(Date.parse(props.car.firstreg));
  const [fuel, setFuel] = useState(props.car.fuel);
  const fuelOptions = ["Benzin", "Dízel", "Elektromos", "Hibrid"];

  const [cubCapacity, setCubCapacity] = useState(props.car.cubCapacity);
  const [powerKW, setPowerKW] = useState(props.car.powerKw);
  const [driveType, setDriveType] = useState(props.car.driveType);
  const driveTypeOptions = ["Első kerék", "Hátsó kerék", "Össz kerék"];
  const [gearBoxType, setGearBoxType] = useState(props.car.gearBoxType);
  const gearBoxTypeOptions = ["Manuális", "Automata"];
  const [description, setDescription] = useState(props.car.desc);

  const [images, setImages] = React.useState([]);
  const maxNumber = 69;
  const [imgErrorMessage, setImgErrorMessage] = useState("");
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  useEffect(() => {
    const getImg = async (url) => {
      fetch(url)
        .then((res) => res.blob()) // Gets the response and returns it as a blob
        .then((blob) => {
          const objectURL = URL.createObjectURL(blob);
          const fileName = "edited." + blob.type.split("/")[1];
          const file = new File([blob], fileName, { type: blob.type });

          const img_item = {
            data_url: objectURL,
            file: file,
          };
          setImages((images) => [...images, img_item]);
        });
    };

    if (props.openEdit) {
      props.car.carimgs.map((img) => getImg(img.fileUrl));
    } else {
      setImages([]);
    }
  }, [token, props]);

  const createVehicleJSON = () => {
    //Itt még hátra van egy első körös validálás
    const { format } = require("date-fns");
    const JSON = {
      id: 0,
      published: isPublic,
      brand: brand,
      price: parseInt(price),
      firstreg: format(firstReg, "yyyy-MM-dd").toString(),
      fuel: fuel,
      cubCapacity: parseInt(cubCapacity),
      powerKw: parseInt(powerKW),
      driveType: driveType,
      gearBoxType: gearBoxType,
      desc: description,
    };
    return JSON;
  };

  const updateCar = async () => {
    const requestOptions = {
      method: "PUT",
      headers: {
        accept: "application/json",
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(createVehicleJSON()),
    };

    const response = await fetch(
      "https://sincrocars-backend.herokuapp.com/admin/car/" +
        props.car.id.toString(),
      requestOptions
    );

    if (!response.ok) {
      console.log("Baj van nincs update");
    } else {
      await destroyImgs();
      await uploadImgs();
      console.log("Királyság a verda updateelődött");
      setIsProgress(false);
      props.handleCloseEdit();
    }
  };

  const destroyImgs = async () => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        accept: "*/*",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(
      "https://sincrocars-backend.herokuapp.com/img/" + props.car.id.toString(),
      requestOptions
    );
    if (response.ok) {
      console.log("Sikeres kép feltöltés ");
      console.table(response);
    } else {
      console.log("Sikertelen kép feltöltés");
    }
  };

  const uploadImgs = async () => {
    const formData = new FormData();
    for (var i = 0; i < images.length; i++) {
      formData.append("files", images[i].file);
    }

    const requestOptions = {
      method: "POST",
      headers: {
        accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: formData,
    };

    const response = await fetch(
      "https://sincrocars-backend.herokuapp.com/img/" + props.car.id.toString(),
      requestOptions
    );

    if (response.ok) {
      console.log("Sikeres kép feltöltés ");
      console.table(response);
    } else {
      console.log("Sikertelen kép feltöltés");
    }
  };

  const handleSubmit = (e) => {
    if (typeof images !== "undefined" && images.length > 0) {
      setIsProgress(true);
      updateCar();
    } else {
      setImgErrorMessage("Nem választottál képet!");
      e.preventDefault();
    }
  };
  return (
    <div>
      <Dialog fullScreen open={props.openEdit} onClose={props.handleCloseEdit}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleCloseEdit}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Szerkesztés
            </Typography>
            <Button autoFocus color="inherit" onClick={handleSubmit}>
              Mentés
            </Button>
          </Toolbar>
        </AppBar>
        <Container style={{ paddingTop: "20px" }}>
          {isProgress ? (
            <Grid align="center">
              <CircularProgress align="center" />
              <Typography>
                A kocsi frissítése folyamatban.. Ne frissítsd és ne zárd be az
                oldalt.
              </Typography>
            </Grid>
          ) : (
            <>
              <Typography>{imgErrorMessage}</Typography>
            </>
          )}
          <Typography variant="h5">{props.car.brand}</Typography>
          <form style={{ paddingBottom: "20px" }}>
            <Stack spacing={2}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isPublic}
                    onChange={handleCheckChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Nyilvános?"
                style={{ paddingBottom: "20px" }}
              />
              <TextField
                required
                id="outlined-required"
                label="Márka és modell"
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
              />
              <TextField
                id="outlined-number"
                label="Ár (HUF)"
                inputProps={{
                  inputMode: "numeric",
                  pattern: "^[+-]?([0-9]+.?[0-9]*|.[0-9]+)",
                }} // eslint-disable-line
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
              {isMobile ? (
                <MobileDatePicker
                  label="Első forgalomba helyezés dátuma"
                  value={firstReg}
                  inputFormat="yyyy-MM-dd"
                  disableMaskedInput={true}
                  onChange={(newValue) => {
                    setFirstReg(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              ) : (
                <DesktopDatePicker
                  label="Első forgalomba helyezés dátuma"
                  value={firstReg}
                  inputFormat="yyyy-MM-dd"
                  disableMaskedInput={true}
                  onChange={(newValue) => {
                    setFirstReg(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
              <Autocomplete
                required
                value={fuel}
                options={fuelOptions}
                renderInput={(params) => (
                  <TextField {...params} label="Üzemanyag fajtája" />
                )}
                onChange={(event, newValue) => setFuel(newValue)}
              />

              <TextField
                id="outlined-number"
                label="Hengerűrtartalom (cc)"
                inputProps={{
                  inputMode: "numeric",
                  pattern: "^[+-]?([0-9]+.?[0-9]*|.[0-9]+)",
                }} // eslint-disable-line
                value={cubCapacity}
                onChange={(e) => setCubCapacity(e.target.value)}
              />
              <TextField
                id="outlined-number"
                label="Teljesítmény (kw)"
                inputProps={{
                  inputMode: "numeric",
                  pattern: "^[+-]?([0-9]+.?[0-9]*|.[0-9]+)",
                }} // eslint-disable-line
                value={powerKW}
                onChange={(e) => setPowerKW(e.target.value)}
              />
              <Autocomplete
                required
                value={driveType}
                options={driveTypeOptions}
                renderInput={(params) => (
                  <TextField {...params} label="Hajtás" />
                )}
                onChange={(event, newValue) => setDriveType(newValue)}
              />
              <Autocomplete
                required
                value={gearBoxType}
                options={gearBoxTypeOptions}
                renderInput={(params) => (
                  <TextField {...params} label="Váltó fajtája" />
                )}
                onChange={(event, newValue) => setGearBoxType(newValue)}
              />
              <TextField
                id="outlined-multiline-flexible"
                label="Megjegyzés"
                multiline
                minRows={4}
                maxRows={6}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <Typography>Képek Feltöltése</Typography>
            </Stack>
            <ImageUploading
              multiple
              value={images}
              onChange={onChange}
              maxNumber={maxNumber}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
              }) => (
                <div className="upload__image-wrapper">
                  <ButtonGroup
                    disableElevation
                    variant="contained"
                    style={{ paddingBottom: "15px" }}
                  >
                    <Button onClick={onImageUpload}>Új kép feltöltése</Button>

                    <Button color="error" onClick={onImageRemoveAll}>
                      Az összes kép törlése
                    </Button>
                  </ButtonGroup>
                  <Grid
                    container
                    rowSpacing={5}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    {imageList.map((image, index) => (
                      <Grid item xs={12} md={4} key={index}>
                        <div
                          className="image-item"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingBottom: "25px",
                          }}
                        >
                          <Card sx={{ maxWidth: 345 }} variant="outlined">
                            <CardMedia
                              component="img"
                              image={image["data_url"]}
                              height="300px"
                              alt=""
                            />
                            <CardActions>
                              <Button onClick={() => onImageUpdate(index)}>
                                Módosít
                              </Button>
                              <Button
                                color="error"
                                onClick={() => onImageRemove(index)}
                                startIcon={<DeleteIcon />}
                              >
                                Töröl
                              </Button>
                            </CardActions>
                          </Card>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              )}
            </ImageUploading>
          </form>
        </Container>
      </Dialog>
    </div>
  );
};

export default EditCar;
