import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { useContext } from "react";
import LoginDialog from "../pages/admin-pages/LoginDialog";

import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { useTheme } from "@mui/material/styles";
import { ColorModeContext } from "../context/ToggleColorMode";

const Navbar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [token, setToken] = useContext(UserContext);

  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);

  const handleLogout = () => {
    setToken(null);
    handleCloseUserMenu();
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [openLogin, setOpenLogin] = React.useState(false);

  const handleOpenLogin = () => {
    handleCloseUserMenu();
    setOpenLogin(true);
  };

  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  return (
    <div>
      <AppBar position="fixed">
        <Container maxWidth="xl">
          <Toolbar>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
            >
              SincroCars.
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                    display: "block",
                    color: "inherit",
                  }}
                >
                  <MenuItem key={"key"} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Kezdőlap</Typography>
                  </MenuItem>
                </Link>

                <Link
                  to="/cars"
                  style={{
                    textDecoration: "none",
                    display: "block",
                    color: "inherit",
                  }}
                >
                  <MenuItem key={"key"} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Gépjárművek</Typography>
                  </MenuItem>
                </Link>

                <Link
                  to="/contact"
                  style={{
                    textDecoration: "none",
                    display: "block",
                    color: "inherit",
                  }}
                >
                  <MenuItem key={"key"} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Kapcsolat</Typography>
                  </MenuItem>
                </Link>
              </Menu>
            </Box>

            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
            >
              SincroCars.
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  display: "block",
                  color: "inherit",
                }}
              >
                <Button
                  key={"kulcs"}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  Kezdőlap
                </Button>
              </Link>

              <Link
                to="/cars"
                style={{
                  textDecoration: "none",
                  display: "block",
                  color: "inherit",
                }}
              >
                <Button
                  key={"kulcs"}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  Gépjárművek
                </Button>
              </Link>
              <Link
                to="/contact"
                style={{
                  textDecoration: "none",
                  display: "block",
                  color: "inherit",
                }}
              >
                <Button
                  key={"kulcs"}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  Kapcsolat
                </Button>
              </Link>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Admin Oldalak">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="" />
                </IconButton>
              </Tooltip>
              <IconButton
                sx={{ ml: 1 }}
                onClick={colorMode.toggleColorMode}
                color="inherit"
              >
                {theme.palette.mode === "dark" ? (
                  <Brightness7Icon />
                ) : (
                  <Brightness4Icon />
                )}
              </IconButton>

              {!token ? (
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem key="Kulcs" onClick={handleOpenLogin}>
                    <Typography textAlign="center">Bejelentkezés</Typography>
                  </MenuItem>
                </Menu>
              ) : (
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <Link
                    to="/profile"
                    style={{
                      textDecoration: "none",
                      display: "block",
                      color: "inherit",
                    }}
                  >
                    <MenuItem key="Kulcs" onClick={handleCloseUserMenu}>
                      <Typography textAlign="center">Profil</Typography>
                    </MenuItem>
                  </Link>
                  <Link
                    to="/dashboard"
                    style={{
                      textDecoration: "none",
                      display: "block",
                      color: "inherit",
                    }}
                  >
                    <MenuItem key="Kulcs" onClick={handleCloseUserMenu}>
                      <Typography textAlign="center">Vezérlőpult</Typography>
                    </MenuItem>
                  </Link>
                  <Link
                    to="/"
                    style={{
                      textDecoration: "none",
                      display: "block",
                      color: "inherit",
                    }}
                  >
                    <MenuItem key="Kulcs" onClick={handleLogout}>
                      <Typography textAlign="center">Kijelentkezés</Typography>
                    </MenuItem>
                  </Link>
                </Menu>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar />

      <LoginDialog
        openLogin={openLogin}
        handleOpenLogin={handleOpenLogin}
        handleCloseLogin={handleCloseLogin}
      />
    </div>
  );
};

export default Navbar;
