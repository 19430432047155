import React from "react";
import { Grid, Typography, Divider } from "@mui/material";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
const Footer = () => {
  return (
    <Grid
      container
      style={{ marginTop: "50px", marginBottom: "20px", padding: "5px" }}
    >
      <Grid item md={12} sm={12}>
        <Divider>
          <Typography variant="h2" gutterBottom>
            Kapcsolat
          </Typography>
        </Divider>
        <Typography gutterBottom>
          Kérdéssel kapcsolatban, kérjük vegyék fel velünk a kapcsolatot az
          alábbi elérhetőségek egyikén.
        </Typography>
        <LocalPhoneOutlinedIcon />
        <Typography gutterBottom>+36 30 943 2245</Typography>
        <LocationOnOutlinedIcon />
        <Typography gutterBottom>
          4031 Debrecen, Bartók Béla utca 115
        </Typography>
        <EmailOutlinedIcon />
        <Typography gutterBottom>auto@sincrocars.hu</Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
