import React from "react";
import {
  Card,
  CardContent,
  Button,
  CardActions,
  CardHeader,
  CardMedia,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  
} from "@mui/material";

import WbIncandescentOutlinedIcon from "@mui/icons-material/WbIncandescentOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LocalGasStationOutlinedIcon from "@mui/icons-material/LocalGasStationOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import { Link } from "react-router-dom";

const PublicCarCard = ({ car }) => {
  const car_link = "/car/" + car.id;
  
  let car_img_link = "";
  if(typeof car.carimgs !== 'undefined' && car.carimgs.length > 0){
    car_img_link = car.carimgs[0].fileUrl;
  }
  
  return (
    <Card variant="outlined" style={{ borderRadius: "20px", boxShadow: "1px" }}>
      <CardHeader title={car.brand} subheader={<Typography color="text.secondary">{car.firstreg.substring(0, 4) }</Typography>}/>
      <CardMedia component="img" height="194" style={{padding:"15px", borderRadius: "25px"}} image={car_img_link} alt="Paella dish" />
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" align="left">
          Ár
        </Typography>
        <Typography variant="h5" component="div" align="left" color="secondary.main">
          {car.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ft
        </Typography>

        <List>
          <ListItem disablePadding>
            <ListItemIcon>
              <EventNoteOutlinedIcon color="info"/>
            </ListItemIcon>
            <ListItemText
              primary={"Évjárat: " + car.firstreg.substring(0, 4)}
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <LocalGasStationOutlinedIcon color="info"/>
            </ListItemIcon>
            <ListItemText primary={"Üzemanyag: " + car.fuel} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <SettingsOutlinedIcon color="info"/>
            </ListItemIcon>
            <ListItemText primary={"Váltó típusa: " + car.gearBoxType} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <WbIncandescentOutlinedIcon color="info"/>
            </ListItemIcon>
            <ListItemText
              primary={"Leírás: " + car.desc.substring(0, 25) + " ..."}
            />
          </ListItem>
        </List>
      </CardContent>
      <CardActions>
        <Link to={car_link} style={{textDecoration: "none"}}>
          <Button size="large" color="info">
            Részletek..
          </Button>
        </Link>
      </CardActions>
    </Card>
  );
};

export default PublicCarCard;
