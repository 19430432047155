import { Grid } from "@mui/material";
import React from "react";
import { useEffect, useState, useContext } from "react";
import { UserContext } from "../../../context/UserContext";
import CarCard from "./CarCard";

const CarList = () => {
  const [token] = useContext(UserContext);
  const [carlist, setCarlist] = useState([]);

  useEffect(() => {
    const getCars = async () => {
      const requestOptions = {
        method: "GET",
        headers: {
          accept: "application/json",
          Authorization: "Bearer " + token,
        },
      };

      const response = await fetch("https://sincrocars-backend.herokuapp.com/admin/car/", requestOptions);
      if (!response.ok) {
        console.log("Houston baj van");
      } else {
        const data = await response.json();
        setCarlist(data);
      }
    };
    getCars();
  }, [token]);



  return (
    <div>
      <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {carlist.map((car) => (
          <Grid item xs={12} md ={4} key={car.id}>
            <CarCard car={car}/>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default CarList;
