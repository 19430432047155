import React from 'react'

const Profile = () => {
    
    
    return (
        <div>
          Profil
                   


        </div>
    )
}

export default Profile
