import React, { useContext, useState } from "react";
import { UserContext } from "../../../context/UserContext";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import { Button } from "@mui/material";

const DeleteCar = (props) => {
  const [token] = useContext(UserContext);
  const [isProgress, setIsProgress] = useState(false);
  const handleDeleteCar = async () => {
    setIsProgress(true);
    const requestOptions = {
      method: "DELETE",
      headers: {
        accept: "*/*",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(
      "https://sincrocars-backend.herokuapp.com/admin/car/" + props.car.id.toString(),
      requestOptions
    );

    if (!response.ok) {
      return "HIBA!!!";
    } else {
      window.location.reload(true);
    }
  };
  return (
    <div>
      <Dialog open={props.openDelete} onClose={props.handleOpenDelete}>
        <DialogTitle>TÖRLÉS: {props.car.brand}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Biztos vagy benne, hogy törölni szeretnéd?
          </DialogContentText>
          {isProgress ? (
            <>
              <DialogContentText align="center">Törlés folyamatban..</DialogContentText>
              <DialogContentText align="center">
                <CircularProgress />
              </DialogContentText>
            </>
          ) : (
            <></>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCar}>Igen</Button>
          <Button onClick={props.handleCloseDelete}>Mégsem</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteCar;
