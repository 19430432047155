import React, { useState, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Button, TextField } from "@mui/material";

const LoginDialog = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [, setToken] = useContext(UserContext);

  const submitLogin = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: JSON.stringify(
        `grant_type=&username=${email}&password=${password}&scope=&client_id=&client_secret=`
      ),
    };

    const response = await fetch("https://sincrocars-backend.herokuapp.com/login", requestOptions);
    const data = await response.json();

    if (!response.ok) {
      setErrorMessage('Hiba: ' + data.detail);
    } else {
      setToken(data.access_token);
      props.handleCloseLogin();
    }
  };

  const handleSubmit = (e) => {
    submitLogin();
  };


  return (
    <div className="column">
      
      <Dialog open={props.openLogin} onClose={props.handleOpenLogin}>
        <DialogTitle>Bejelentkezés</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Bejelentkezéshez kérlek írd be az email címed és jelszavad. :) {errorMessage}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Cím"
            type="email"
            fullWidth
            variant="standard"
            onChange={(e) =>setEmail(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Jelszó"
            type="password"
            fullWidth
            variant="standard"
            onChange={(e) =>setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button 
          onClick={handleSubmit}>Rendben</Button>
          <Button onClick={props.handleCloseLogin}>Mégsem</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LoginDialog;
