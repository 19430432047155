import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

import WbIncandescentOutlinedIcon from "@mui/icons-material/WbIncandescentOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LocalGasStationOutlinedIcon from "@mui/icons-material/LocalGasStationOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";

import DeleteIcon from "@mui/icons-material/Delete";

import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import DeleteCar from "./DeleteCar";
import EditCar from "./EditCar";

const CarCard = ({ car }) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  
  

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  
  let car_img_link = "";
  if(typeof car.carimgs !== 'undefined' && car.carimgs.length > 0){
    car_img_link = car.carimgs[0].fileUrl;
  }
  
  return (
    <Card variant="outlined">
      <CardMedia component="img" height="140" image={car_img_link} alt="green iguana" />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {car.brand}
        </Typography>
        <Typography>{car.price + " HUF"}</Typography>

        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemIcon>
              <EventNoteOutlinedIcon color="info"/>
            </ListItemIcon>
            <ListItemText
              primary={"Évjárat: " + car.firstreg.substring(0, 4)}
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <LocalGasStationOutlinedIcon color="info"/>
            </ListItemIcon>
            <ListItemText primary={"Üzemanyag: " + car.fuel} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <SettingsOutlinedIcon color="info"/>
            </ListItemIcon>
            <ListItemText primary={"Váltó típusa: " + car.gearBoxType} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <WbIncandescentOutlinedIcon color="info"/>
            </ListItemIcon>
            <ListItemText
              primary={"Leírás: " + car.desc.substring(0, 25) + " ..."}
            />
          </ListItem>
        </List>
      </CardContent>
      <CardActions>
        <Tooltip title="Szerkesztés" arrow>
          <Button onClick={handleOpenEdit} color="info">Szerkesztés</Button>
        </Tooltip>
        <Tooltip title="Törlés" arrow>
          <Button
            color="error"
            onClick={handleOpenDelete}
            startIcon={<DeleteIcon />}
          >
            Törlés
          </Button>
        </Tooltip>
      </CardActions>
      <DeleteCar
        openDelete={openDelete}
        handleOpenDelete={handleOpenDelete}
        handleCloseDelete={handleCloseDelete}
        car={car}
      />
      <EditCar
        openEdit={openEdit}
        handleOpenEdit={handleOpenEdit}
        handleCloseEdit={handleCloseEdit}
        car={car}
      />
    </Card>
  );
};

export default CarCard;
