import React from "react";
import { useState, useContext } from "react";
import { Grid, Container, Stack } from "@mui/material";
import { TextField } from "@mui/material";
import { Button } from "@mui/material";
import { DesktopDatePicker, MobileDatePicker } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery, CircularProgress, Typography } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { Switch } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import ImageUploading from "react-images-uploading";
import { Divider } from "@mui/material";
import { ButtonGroup } from "@mui/material";
import { Card, CardMedia, CardActions } from "@mui/material";
import { UserContext } from "../../../context/UserContext";
import DeleteIcon from "@mui/icons-material/Delete";

const NewCar = (params) => {
  const theme = useTheme();
  const [token] = useContext(UserContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isProgress, setIsProgress] = useState(false);

  const [isPublic, setIsPublic] = useState(true);

  const handleCheckChange = (event) => {
    setIsPublic(event.target.checked);
  };
  const [brand, setBrand] = useState("");
  const [price, setPrice] = useState("");
  const [firstReg, setFirstReg] = useState(new Date());
  const [fuel, setFuel] = useState("Benzin");
  const fuelOptions = ["Benzin", "Dízel", "Elektromos", "Hibrid"];

  const [cubCapacity, setCubCapacity] = useState("");
  const [powerKW, setPowerKW] = useState("");
  const [driveType, setDriveType] = useState("Első kerék");
  const driveTypeOptions = ["Első kerék", "Hátsó kerék", "Össz kerék"];
  const [gearBoxType, setGearBoxType] = useState("Automata");
  const gearBoxTypeOptions = ["Manuális", "Automata"];
  const [description, setDescription] = useState("");

  const [images, setImages] = React.useState([]);
  const maxNumber = 69;
  const [imgErrorMessage, setImgErrorMessage] = useState('');
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  const createVehicleJSON = () => {
    //Itt még hátra van egy első körös validálás
    const { format } = require("date-fns");
    const JSON = {
      id: 0,
      published: isPublic,
      brand: brand,
      price: parseInt(price),
      firstreg: format(firstReg, "yyyy-MM-dd").toString(),
      fuel: fuel,
      cubCapacity: parseInt(cubCapacity),
      powerKw: parseInt(powerKW),
      driveType: driveType,
      gearBoxType: gearBoxType,
      desc: description,
    };
    return JSON;
  };

  const createCar = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        accept: "application/json",
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(createVehicleJSON()),
    };

    const response = await fetch("https://sincrocars-backend.herokuapp.com/admin/car/", requestOptions);

    if (!response.ok) {
      return false;
    } else {
      let json = await response.json();
      const jsonId = json.id;
      console.log("JSONID: " + jsonId.toString());
      await postImgs(jsonId);
      return true;
    }
  };

  const postImgs = async (jsonId) => {
    const formData = new FormData();
    for (var i = 0; i < images.length; i++) {
      formData.append("files", images[i].file);
    }

    const requestOptions = {
      method: "POST",
      headers: {
        accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: formData,
    };

    const response = await fetch("https://sincrocars-backend.herokuapp.com/img/" + jsonId.toString(), requestOptions);

    if (response.ok) {
      params.controlNewCarPanel();
      console.log("Sikeres kép feltöltés ");
      setIsProgress(false);
      console.table(response);
    } else {
      console.log("Sikertelen kép feltöltés");
    }
  };

  const handleSubmit = (e) => {
    if (typeof images !== 'undefined' && images.length > 0){
      setIsProgress(true);
      const success = createCar();
      if (success) {
        e.preventDefault();
      } else {
        e.preventDefault();
      }
    }else{
      setImgErrorMessage("Nem választottál képet!");
      e.preventDefault();
    }
    
  };

  const handleCancel = () => {
    params.controlNewCarPanel();
  };

  return (
    <div>
      <h1>Új Gépjármű</h1>

      <form onSubmit={handleSubmit} style={{ paddingBottom: "20px" }}>
        <FormControlLabel
          control={
            <Switch
              checked={isPublic}
              onChange={handleCheckChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Nyilvános?"
          style={{ paddingBottom: "20px" }}
        />
        <Grid container>
          <Grid item xs={12} md={6}>
            <Container style={{ paddingBottom: "15px" }}>
              <Stack spacing={2}>
                <TextField
                  required
                  id="outlined-required"
                  label="Márka és modell"
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                />
                <TextField
                  id="outlined-number"
                  label="Ár (HUF)"
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "^[+-]?([0-9]+.?[0-9]*|.[0-9]+)",
                  }} // eslint-disable-line
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
                {isMobile ? (
                  <MobileDatePicker
                    label="Első forgalomba helyezés dátuma"
                    value={firstReg}
                    inputFormat="yyyy-MM-dd"
                    disableMaskedInput={true}
                    onChange={(newValue) => {
                      setFirstReg(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                ) : (
                  <DesktopDatePicker
                    label="Első forgalomba helyezés dátuma"
                    value={firstReg}
                    inputFormat="yyyy-MM-dd"
                    disableMaskedInput={true}
                    onChange={(newValue) => {
                      setFirstReg(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                )}

                <Autocomplete
                  required
                  value={fuel}
                  options={fuelOptions}
                  renderInput={(params) => (
                    <TextField {...params} label="Üzemanyag fajtája" />
                  )}
                  onChange={(event, newValue) => setFuel(newValue)}
                />

                <TextField
                  id="outlined-number"
                  label="Hengerűrtartalom (cc)"
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "^[+-]?([0-9]+.?[0-9]*|.[0-9]+)",
                  }} // eslint-disable-line
                  value={cubCapacity}
                  onChange={(e) => setCubCapacity(e.target.value)}
                />
              </Stack>
            </Container>
          </Grid>
          <Grid item xs={12} md={6}>
            <Container style={{ paddingBottom: "15px" }}>
              <Stack spacing={2}>
                <TextField
                  id="outlined-number"
                  label="Teljesítmény (kw)"
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "^[+-]?([0-9]+.?[0-9]*|.[0-9]+)",
                  }} // eslint-disable-line
                  value={powerKW}
                  onChange={(e) => setPowerKW(e.target.value)}
                />
                <Autocomplete
                  required
                  value={driveType}
                  options={driveTypeOptions}
                  renderInput={(params) => (
                    <TextField {...params} label="Hajtás" />
                  )}
                  onChange={(event, newValue) => setDriveType(newValue)}
                />
                <Autocomplete
                  required
                  value={gearBoxType}
                  options={gearBoxTypeOptions}
                  renderInput={(params) => (
                    <TextField {...params} label="Váltó fajtája" />
                  )}
                  onChange={(event, newValue) => setGearBoxType(newValue)}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Megjegyzés"
                  multiline
                  minRows={4}
                  maxRows={6}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Stack>
            </Container>
          </Grid>
        </Grid>

        <Container style={{ paddingBottom: "15px" }}>
          <Divider />

          <h1>Képek feltöltése</h1>

          <ImageUploading
            multiple
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
            }) => (
              <div className="upload__image-wrapper">
                <ButtonGroup
                  disableElevation
                  variant="contained"
                  style={{ paddingBottom: "15px" }}
                >
                  <Button onClick={onImageUpload}>Új kép feltöltése</Button>

                  <Button color="error" onClick={onImageRemoveAll}>
                    Az összes kép törlése
                  </Button>
                </ButtonGroup>
                <Grid
                  container
                  rowSpacing={5}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  {imageList.map((image, index) => (
                    <Grid item xs={12} md={4} key={index}>
                      <div
                        className="image-item"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingBottom: "25px",
                        }}
                      >
                        <Card sx={{ maxWidth: 345 }} variant="outlined">
                          <CardMedia
                            component="img"
                            image={image["data_url"]}
                            height="300px"
                            alt=""
                          />
                          <CardActions>
                            <Button onClick={() => onImageUpdate(index)}>
                              Módosít
                            </Button>
                            <Button
                              color="error"
                              onClick={() => onImageRemove(index)}
                              startIcon={<DeleteIcon />}
                            >
                              Töröl
                            </Button>
                          </CardActions>
                        </Card>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
            )}
          </ImageUploading>
          <Divider style={{ paddingBottom: "15px" }} />
        </Container>

        <ButtonGroup>
          <Button variant="contained" type="submit">
            Rendben
          </Button>
          <Button variant="contained" color="error" onClick={handleCancel}>
            Mégsem
          </Button>
        </ButtonGroup>
        <Grid style={{ paddingTop: "10px" }}>
          {isProgress ? (
            <>
              <CircularProgress />
              <Typography>A kocsi feltöltése folyamatban.. Ne frissítsd és ne zárd be az oldalt.</Typography>
            </>
          ) : (
            <><Typography>{imgErrorMessage}</Typography></>
          )}
        </Grid>
      </form>
    </div>
  );
};

export default NewCar;
